import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
  SectionHeading as HeadingTitle,
  Subheading,
} from 'components/misc/Headings.js';
import {
  PrimaryButton as PrimaryButtonBase,
  OutlineButton,
} from 'components/misc/Buttons.js';

import { ReactComponent as UserIcon } from 'feather-icons/dist/icons/user.svg';
import { ReactComponent as TagIcon } from 'feather-icons/dist/icons/tag.svg';
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-1.svg';
import { ReactComponent as SvgDecoratorBlob2 } from '../../images/svg-decorator-blob-3.svg';

import ManbooGuide from '../../images/manboo-guide.gif';
import ForcutGuide from '../../images/fourcut-about.png';
import PetWizGuide from '../../images/petwiz-guide.jpg';
import MakepicGuide from '../../images/makepic-guide.gif';
import MiniPhoneGuide from '../../images/miniphone-guide.png';

import MakepicGuideMp4 from '../../videos/makepic-guide.mp4';
import ManbooGuideMp4 from '../../videos/manboo-guide.mp4';
import FourcutGuideMp4 from '../../videos/fourcut-guide.mp4';
import MiniPhoneGuideMp4 from '../../videos/miniphone-guide.mp4';
import PetWizGuideMp4 from '../../videos/petwiz-guide.mp4';

const Container = tw.section`relative pt-[50px] lg:pt-[160px]`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:pb-16 lg:pt-0`;
const Columns = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row lg:justify-center flex-wrap `;
const Column = tw.div`mt-10 w-full lg:w-1/2 px-10 lg:px-0`;
const ThreeColumnItem = tw(Column)`lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center -mt-[150px] pt-[100px] lg:pt-[150px]`;

const HeadingInfoContainer2 = tw(HeadingInfoContainer)`
  -mt-[50px] pt-[100px] lg:pt-[150px]
`;

const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-lg flex flex-col h-full mx-auto`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center rounded rounded-b-none aspect-square`,
]);

const Details = tw.div`py-6 px-2 rounded border-none rounded-t-none block text-center`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-1 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(OutlineButton).attrs({ as: 'a' })`
  ${tw`inline-block mt-2 lg:mt-4 text-sm font-semibold px-4 py-2`}
`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = null,
  heading = 'Our Solutions',
  description = null,
}) => {
  const servicePosts = [
    {
      videoSrc: MakepicGuideMp4,
      category: 'Web Service',
      title: 'makepic - 커머스 생성 AI',
      description: '',
      url: 'https://makepic.ai',
    },
    {
      videoSrc: ManbooGuideMp4,
      //imageSrc: ManbooGuide,
      category: 'Web Service',
      title: '만부(MANBOO) - 3D 콘티 제작 솔루션',
      description: '',
      url: 'https://manboo.io',
    },

    {
      videoSrc: FourcutGuideMp4,
      //imageSrc: ForcutGuide,
      category: 'Mobile Application',
      title: '네컷만화 - 말로그리는 AI 콘텐츠 에디터',
      description: '',
      url: 'https://www.notion.so/de428c02765e4e63bb9b7d72d2236fb2?pvs=4',
    },
  ];

  const productPosts = [
    {
      videoSrc: PetWizGuideMp4,
      //imageSrc: PetWizGuide,
      category: 'Mobile Application',
      title: 'PETWIZ - AI 스마트 자동 급식기',
      description: '',
      url: 'http://petwiz.kr',
    },
    {
      videoSrc: MiniPhoneGuideMp4,
      //imageSrc: MiniPhoneGuide,
      category: 'Mobile Device',
      title: '미니폰 - 프리미엄 키즈용 스마트폰',
      description: '',
      url: 'https://silent-appliance-eb1.notion.site/mini-f6372bb20c024312ad580ccae538e5af',
    },
  ];

  return (
    <Container>
      <Content>
        <HeadingInfoContainer id="Service">
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>Our Services</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <Columns>
          {servicePosts.map((post, index) => (
            <ThreeColumnItem key={index}>
              <CardItem post={post} />
            </ThreeColumnItem>
          ))}
        </Columns>

        <HeadingInfoContainer2 id="Product">
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>Our Products</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer2>

        <Columns>
          {productPosts.map((post, index) => (
            <ThreeColumnItem key={index}>
              <CardItem post={post} />
            </ThreeColumnItem>
          ))}
        </Columns>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

const CardItem = ({ post }) => {
  return (
    <Card>
      {post.imageSrc && <Image imageSrc={post.imageSrc} />}
      {post.videoSrc && (
        <video
          preload="auto"
          autoPlay
          loop
          muted
          playsInline={true}
          style={{
            maxWidth: '450px',
            borderRadius: '5px',
            aspectRatio: 1,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            objectFit: 'cover',
            border: '1px solid rgba(20,20,20,.1)',
          }}
        >
          <source src={post.videoSrc} type="video/mp4" />
        </video>
      )}
      <Details>
        {/* <MetaContainer>
          <Meta>
            <TagIcon />
            <div>{post.category}</div>
          </Meta>
        </MetaContainer> */}
        <Title>{post.title}</Title>
        <Description>{post.description}</Description>
        <Link
          href={post.url}
          target="_blank"
          onClick={(e) => e.currentTarget.blur()}
        >
          더보기
        </Link>
      </Details>
    </Card>
  );
};
